/* Change this file to get your Portfolio */

//Website-related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Mahmud's Portfolio",
  description:
    "As a passionate and driven individual, I take on each project as a personal challenge and strive to strike the perfect balance between complexity and responsibility in my work.",
  og: {
    title: "Saif Mahmud Portfolio",
    type: "website",
    url: "https://vmsaif.github.io",
  },
};

//Home Page
const greeting = {
  title: "Saif Mahmud",
  logo_name: "Saif Mahmud",
  nickname: "vmsaif",
  subTitle:
    "As a passionate and driven individual, I take on each project as a personal challenge and strive to strike the perfect balance between complexity and responsibility in my work.",
  resumeLink:
    "https://drive.google.com/drive/folders/1fAoLfBRRr6bFjjFUrKqlAtW_3NH0dDDQ?usp=share_link",
  portfolio_repository: "https://github.com/vmsaif/",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/vmsaif",
  // linkedin: "https://www.linkedin.com/in/vmsaif/",
  // gmail: "msaifofficial@gmail.com",
  // gitlab: "https://code.cs.umanitoba.ca/vmsaif",
  // facebook: "https://www.facebook.com/vmsaif/",
  // twitter: "https://twitter.com/mahmudSaif_",
  // instagram: "https://www.instagram.com/mahmud.saif_/"

  {
    name: "Discord",
    link: "https://discord.gg/HNYsWfzUmb",
    fontAwesomeIcon: "fa-brands fa-discord", // Reference https://fontawesome.com/icons/discord?s=brands
    backgroundColor: "#183153",
  },
  {
    name: "Github",
    link: "https://github.com/vmsaif",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/vmsaif/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "Skype",
  //   link: "https://join.skype.com/invite/Dw35OtPi2DpI",
  //   fontAwesomeIcon: "fa-brands fa-skype", // Reference https://fontawesome.com/icons/skype?s=brands
  //   backgroundColor: "#183153",
  // },
  {
    name: "YouTube",
    link: "https://www.youtube.com/channel/UCNeqah8GH6GcmhMvU8kgwXg",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:msaifofficial@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "Twitter",
  //   link: "https://twitter.com/mahmudSaif_",
  //   fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
  //   backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  // },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/vmsaif/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  // {
  //   name: "Instagram",
  //   link: "https://www.instagram.com/mahmud.saif_/",
  //   fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
  //   backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  // },
];

const skills = {
  data: [
    {
      title: "Generative AI Development",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Experience in developing multi-agent systems with CrewAI, LangChain, Gemini, and RAG Search for AI-driven applications.",
        "⚡ Expertise in algorithms like A* Search, BFS, DFS, Dijkstra, Minimax, Alpha-Beta Pruning, and Q-Learning to create AI for Games and professional applications.",
        "⚡ Experience in working with Database management with SQL, MongoDB, and JSON.",
      ],
      softwareSkills: [
        {
          skillName: "OpenAI",
          fontAwesomeClassname: "logos:openai",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Mistral",
          fontAwesomeClassname: "logos:mistral-ai-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "LangChain",
          fontAwesomeClassname: "system-uicons:chain",
          style: {
            // backgroundColor: "white",
            // color: "#D00000",
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "CrewAI",
          fontAwesomeClassname: "codicon:type-hierarchy",
          style: {
            backgroundColor: "transparent",
          },
        },

        // simple-icons:openai

        {
          skillName: "Gemini",
          fontAwesomeClassname: "logos:google-gemini",
          style: {
            backgroundColor: "transparent",
          },
        },

        {
          skillName: "RAG Search",
          fontAwesomeClassname: "fluent:brain-circuit-20-filled",
          style: {
            backgroundColor: "transparent",
          },
        },

        {
          skillName: "Java",
          fontAwesomeClassname: "logos:java",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        // {
        //   skillName: "Tensorflow",
        //   fontAwesomeClassname: "logos-tensorflow",
        //   style: {
        //     backgroundColor: "transparent",
        //   },
        // },
        {
          skillName: "SQL",
          fontAwesomeClassname: "carbon:sql",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "logos:mongodb",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },

    {
      title: "Expertise in CD/CI, Github Action",
      fileName: "vsc", //reference https://undraw.co/
      skills: [
        "⚡ Developed a GitHub Actions workflow for building and releasing software across multiple platforms (macOS, Ubuntu, Windows).",
        "⚡ Extracted release notes from changelogs and automated the creation of GitHub releases with attached assets.",
        "⚡ Created a personal resume using GitHub Pages and Jekyll theme, showcasing proficiency in continuous deployment for static sites.",
      ],
      softwareSkills: [
        {
          skillName: "git",
          fontAwesomeClassname: "bi:git", // Reference https://icon-sets.iconify.design/bi/git/
          style: {
            color: "#F05032",
          },
        },
        {
          skillName: "Github",
          fontAwesomeClassname: "akar-icons:github-fill", // Reference https://icon-sets.iconify.design/akar-icons/github-fill/
          style: {
            color: "#181717",
          },
        },
        {
          skillName: "CD/CI",
          fontAwesomeClassname: "ph:infinity-bold", // reference https://icon-sets.iconify.design/ph/infinity-bold/
          style: {
            color: "#08445A",
          },
        },
        {
          skillName: "Github Action",
          fontAwesomeClassname: "logos:github-actions", // reference https://icon-sets.iconify.design/logos/github-actions/
          style: {
            color: "#2088FF",
          },
        },
        {
          skillName: "Jekyll",
          fontAwesomeClassname: "logos:jekyll", // You might need to find the appropriate icon reference
          style: {
            color: "#FC0",
          },
        },
        {
          skillName: "GitHub Pages",
          fontAwesomeClassname: "akar-icons:github-fill", // Using the same GitHub icon as it represents GitHub Pages too
          style: {
            color: "#181717",
          },
        },
        {
          skillName: "Code Branch",
          fontAwesomeClassname: "charm:git-branch", // reference https://icon-sets.iconify.design/charm/git-branch/
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "code-pull-request",
          fontAwesomeClassname: "bx:git-pull-request", // reference https://icon-sets.iconify.design/bx/git-pull-request/
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "code-merge",
          fontAwesomeClassname: "charm:git-merge", // reference https://icon-sets.iconify.design/charm/git-merge/
          style: {
            color: "#000000",
          },
        },
      ],
    },

    {
      title: "Android App Development",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on Android App Development with the implementation of a user-centered design approach according to Human-Computer Interaction.",
        "⚡ Designing fully responsive and attractive interfaces with XML, Java and Kotlin on Android Studio.",
        "⚡ Experience with implementing hyperSQL database in Android Studio.",
      ],
      softwareSkills: [
        {
          skillName: "XML",
          fontAwesomeClassname: "vscode-icons:file-type-xml",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Kotlin",
          fontAwesomeClassname: "logos:kotlin",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "logos:java",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "Android Studio",
          fontAwesomeClassname: "mdi:android-studio",
          style: {
            color: "#0089D6",
          },
        },
      ],
    },

    {
      title: "Web Design & Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Certified Internet Webmaster (CIW) from New Horizon LLC in 2015.",
        "⚡ Expertise in HTML5, CSS3, JavaScript and Content Management Systems like WordPress and Drupal.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Wordpress",
          fontAwesomeClassname: "bi:wordpress",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Drupal",
          fontAwesomeClassname: "logos:drupal-icon",
          style: {
            color: "#339933",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "Google Cloud Skills Boost",
      iconifyClassname: "devicon:googlecloud",
      style: {
        color: "#2EC866",
      },
      profileLink:
        "https://www.cloudskillsboost.google/public_profiles/f742db9b-2bc8-4ad4-a749-53eb8ca31026",
    },

    {
      siteName: "GitHub",
      iconifyClassname: "devicon:github",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://github.com/vmsaif",
    },
    // {
    //   siteName: "HackerRank",
    //   iconifyClassname: "simple-icons:hackerrank",
    //   style: {
    //     color: "#2EC866",
    //   },
    //   profileLink: "",
    // },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "",
    // },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "",
    // },
    // {
    //   siteName: "Hackerearth",
    //   iconifyClassname: "simple-icons:hackerearth",
    //   style: {
    //     color: "#323754",
    //   },
    //   profileLink: "",
    // },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "University of Manitoba",
      subtitle: "Bachelor of Computer Science, Major",
      logo_path: "uofm.png",
      alt_name: "UofM",
      duration: "2018 - 2023",
      descriptions: [
        "⚡ I have learned Artificial Intelligence, Database Management Systems, Statistics and Computing, Data Structures, Algorithms, Operating Systems, Software Engineering, Human-Computer Interactions, Object Orientation, Computer Networks, Computer Graphics, AI and Game Development etc.",
        "⚡ Apart from this, I have taken courses in Supply Chain Management and Psychology.",
      ],
      website_link: "https://sci.umanitoba.ca/cs/",
    },
    {
      title: "International College of Manitoba (ICM)",
      subtitle: "University Transfer Protocol Stage II: Science",
      logo_path: "icm_logo.png",
      alt_name: "icm",
      duration: "2016 - 2017",
      descriptions: [
        "⚡ I have taken courses in Calculus, Linear Algebra, Statistics, and Environmental Science.",
        "⚡ Apart from this, I have taken courses in Micro Economics, Macro Economics, Thematic Approaches to the Study of Literature, Agribusiness Management, Economics of World Food Issues, etc.",
      ],
      website_link: "https://www.icmanitoba.ca/",
    },

    {
      title: "University of Manitoba & International College of Manitoba",
      subtitle: "Courses I've Completed",
      logo_path: "UM_Logo_VERT_RGB.png",
      alt_name: "UofM",
      duration: "",
      descriptions: [
        "⚡ Specialization in the Artificial Intelligence:",
        "-----------------------------------------------------------------------",
        "AI for Game Developers",
        "Introduction to Artificial Intelligence",
        "-----------------------------------------------------------------------",
        "⚡ Specialization in the Databases:",
        "-----------------------------------------------------------------------",
        "Databases 1: Concepts and Usage",
        "Databases 2: Database Implementation",
        "-----------------------------------------------------------------------",
        "⚡ Rest of the Computer Courses:",
        "-----------------------------------------------------------------------",
        "Professional Practice in Computer Science",
        "Technical Communication in Computer Science",
        "Operating Systems",
        "Computer Networks",
        "Computer Graphics",
        "Distributed Computing",
        "Software Engineering",
        "Computer Graphics",
        "Human-Computer Interaction",
        "Object Orientation",
        "Analysis of Algorithms",
        "Data Structures and Algorithms",
        "Programming Practices",
        "Introduction to Computer Systems",
        "Introductory Computer Science 2",
        "Introductory Computer Science 1",
        "Computing: Ideas and Innovation",
        "Navigating Your Digital World",
        "Introductory Computer Usage 1",
        "-----------------------------------------------------------------------",
        "⚡ Statistics Courses:",
        "-----------------------------------------------------------------------",
        "Statistics and Computing",
        "Basic Statistical Analysis 2",
        "Basic Statistical Analysis",
        "-----------------------------------------------------------------------",
        "⚡ Math Courses:",
        "-----------------------------------------------------------------------",
        "Introduction to Calculus",
        "Vector Geometry and Linear Algebra",
        "Discrete Mathematics",
        "-----------------------------------------------------------------------",
        "⚡ And 11 other courses from the faculty of Management, Arts and Agribusiness.",
      ],
      website_link: "https://umanitoba.ca/",
    },

    {
      title: "New Horizon Computer Learning Center - Bangladesh",
      subtitle: "Certified Internet Webmaster(CIW) - Web Design Specialist",
      logo_path: "NewHorizonCLC.png",
      alt_name: "CIW_Certificate",
      duration: "August, 2014 - January, 2015",
      descriptions: [
        "⚡ I completed the CIW - Web Design Specialist certificate in January 2015.",
        "⚡ I have also taken the course Certified Ethical Hacker (CEH) from this institution. However, I did not take the certification examination.",
      ],
      website_link: "https://dhaka.newhorizons.com/",
    },
    {
      title: "Ahsanullah University of Science and Technology",
      subtitle: "Industrial Production Engineering (IPE), 1 Year",
      logo_path: "aust.svg",
      alt_name: "aust",
      duration: "October, 2014 - October, 2015",
      descriptions: [
        "⚡ At this university, I started my Engineering program in Industrial Production. I have studied for two semesters (6 months Each) there. Then I decided to change my study and do my undergraduate in Computer Science in Canada.",
        '⚡During my time here, I was a volunteer at the "House of Volunteers - Ahsanullah University of Science and Technology."',
      ],
      website_link: "https://www.aust.edu/",
    },
    {
      title: "Goethe Institut - Bangladesh",
      subtitle: "German Language Learning Center",
      logo_path: "goethe.png",
      alt_name: "Goethe Institut",
      duration: "April 2015 - October 2015",
      descriptions: [
        "⚡ I have taken German Language(Deutsch) level A1 from April 2015 to July 2015.",
        "⚡ I have also taken Language Level A2 from August 2015 to October 2015.",
      ],
      website_link: "https://www.goethe.de/ins/bd/en/index.html",
    },
    {
      title: "British Council Bangladesh",
      subtitle:
        "International English Language Testing System (IELTS), Academic",
      logo_path: "britishCouncil.png",
      alt_name: "British_Council_Ielts_Bangladesh",
      duration: "January 2015",
      descriptions: [
        "⚡ I have taken the Academic IELTS test and achieved a score of 6.5 out of 9",
      ],
      website_link: "https://www.britishcouncil.org.bd/en",
    },
    {
      title: "Ideal College",
      subtitle:
        "Higher Secondary Certificate: Department of Science, Class 11 & 12",
      logo_path: "Ideal_College_logo_2019.png",
      alt_name: "Ideal College",
      duration: "2012 - 2014",
      descriptions: [
        "⚡ I have taken courses in Higher Maths, Physics, Chemistry, English, Bangla and Computer.",
      ],
      website_link: "https://www.idealcollegedhanmondi.edu.bd/",
    },
    {
      title: "Junior Laboratory High School",
      subtitle:
        "Secondary School Certificate: Department of Science, Class 1-10",
      logo_path: "Junior_Laboratory_high_school.png",
      alt_name: "jlab",
      duration: "2002 - 2012",
      descriptions: [
        "⚡ I have taken courses in Maths, Physics, Chemistry, Biology, Sociology, Religion, English and Bangla.",
      ],
      website_link: "https://www.facebook.com/junior.laboratory/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Transformer Models and BERT Model",
      subtitle: "Cloud Skills Boost - Google",
      logo_path: "Transformer Models and BERT Model.png",
      certificate_link:
        "https://www.cloudskillsboost.google/public_profiles/f742db9b-2bc8-4ad4-a749-53eb8ca31026/badges/6941202",
      alt_name: "google",
      color_code: "#FFFFFF",
    },
    {
      title: "Encoder-Decoder Architecture",
      subtitle: "Cloud Skills Boost - Google",
      logo_path: "Encoder-Decoder Architecture.png",
      certificate_link:
        "https://www.cloudskillsboost.google/public_profiles/f742db9b-2bc8-4ad4-a749-53eb8ca31026/badges/6941038",
      alt_name: "google",
      color_code: "#FFFFFF",
    },
    {
      title: "Attention Mechanism",
      subtitle: "Cloud Skills Boost - Google",
      logo_path: "Attention Mechanism.png",
      certificate_link:
        "https://www.cloudskillsboost.google/public_profiles/f742db9b-2bc8-4ad4-a749-53eb8ca31026/badges/6908835",
      alt_name: "google",
      color_code: "#FFFFFF",
    },

    {
      title: "Introduction to Image Generation",
      subtitle: "Cloud Skills Boost - Google",
      logo_path: "Introduction to Image Generation.png",
      certificate_link:
        "https://www.cloudskillsboost.google/public_profiles/f742db9b-2bc8-4ad4-a749-53eb8ca31026/badges/6908804",
      alt_name: "google",
      color_code: "#FFFFFF",
    },

    {
      title: "Generative AI Fundamentals",
      subtitle: "Cloud Skills Boost - Google",
      logo_path: "Generative AI Fundamentals.png",
      certificate_link:
        "https://www.cloudskillsboost.google/public_profiles/f742db9b-2bc8-4ad4-a749-53eb8ca31026/badges/6867897",
      alt_name: "google",
      color_code: "#FFFFFF",
    },

    {
      title: "Responsible AI: Applying AI Principles",
      subtitle: "Cloud Skills Boost - Google",
      logo_path: "Responsible AI_Applying AI Principles.png",
      certificate_link:
        "https://www.cloudskillsboost.google/public_profiles/f742db9b-2bc8-4ad4-a749-53eb8ca31026/badges/6908630",
      alt_name: "google",
      color_code: "#FFFFFF",
    },

    {
      title: "Introduction to Responsible AI",
      subtitle: "Cloud Skills Boost - Google",
      logo_path: "Introduction to Responsible AI.png",
      certificate_link:
        "https://www.cloudskillsboost.google/public_profiles/f742db9b-2bc8-4ad4-a749-53eb8ca31026/badges/6863660",
      alt_name: "google",
      color_code: "#FFFFFF",
    },

    {
      title: "Introduction to Generative AI",
      subtitle: "Cloud Skills Boost - Google",
      logo_path: "Introduction to Generative AI.png",
      certificate_link:
        "https://www.cloudskillsboost.google/public_profiles/f742db9b-2bc8-4ad4-a749-53eb8ca31026/badges/6777359",
      alt_name: "google",
      color_code: "#FFFFFF",
    },

    {
      title: "University Transfer Protocol Stage II: Science",
      subtitle: "International College of Manitoba (ICM) - 2017",
      logo_path: "icm_logo.png",
      certificate_link: "https://www.icmanitoba.ca/",
      alt_name: "icm",
      color_code: "#FFFFFF",
    },
    {
      title: "Student Councilor",
      subtitle: "International College of Manitoba (ICM)",
      logo_path: "icm_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1ZFkD6gN7OdnqZ1qFwk_F8Y7qTp3o-SPZ/view?usp=sharing",
      alt_name: "icm",
      color_code: "#FFFFFF",
    },
    {
      title: "Certified Internet Webmaster (CIW)",
      subtitle: "Web Design Specialist - 2015",
      logo_path: "NewHorizonCLC.png",
      certificate_link:
        "https://drive.google.com/file/d/10AJfIBJ0z9cLY_fBlU1Hxpb37hajyJaX/view?usp=sharing",
      alt_name: "CIW",
      color_code: "#FFFFFF",
    },
    {
      title: "German Language, Deutsch A2",
      subtitle: "Goethe Institut - Bangladesh, 2015",
      logo_path: "goethe.png",
      certificate_link:
        "https://drive.google.com/file/d/1_1mRh4ei9ZgENDUUv9e3dPFyDCmedRvL/view?usp=sharing",
      alt_name: "goethe",
      color_code: "#FFFFFF",
    },
    {
      title: "German Language, Deutsch A1",
      subtitle: "Goethe Institut - Bangladesh, 2015",
      logo_path: "goethe.png",
      certificate_link:
        "https://drive.google.com/file/d/1xO2ESlhdtPyIfNpjSVzsAb2MZf0kPB6w/view?usp=sharing",
      alt_name: "goethe",
      color_code: "#FFFFFF",
    },
    {
      title: "International English Language Testing System (IELTS)",
      subtitle: "British Council - Bangladesh, 2015, Score 6.5/9.0",
      logo_path: "britishCouncil.png",
      certificate_link:
        "https://drive.google.com/file/d/1m30B0K7w30ZUgYQ7SJjSEJpNBNATX4Bt/view?usp=sharing",
      alt_name: "ielts",
      color_code: "#FFFFFF",
    },
    {
      title: "Higher Secondary Certificate - Science",
      subtitle: "GPA 4.7/5.0, Dhaka Board - 2014, Bangladesh",
      logo_path: "sscHsc.png",
      certificate_link:
        "https://drive.google.com/file/d/1UrW57_r4r7sxawa-cMCnqgjk1lpaDLmZ/view?usp=sharing",
      alt_name: "hsc",
      color_code: "#FFFFFF",
    },
    {
      title: "Secondary School Certificate - Science",
      subtitle: "GPA 5.0/5.0, Dhaka Board - 2014, Bangladesh",
      logo_path: "sscHsc.png",
      certificate_link:
        "https://drive.google.com/file/d/11DHGjItHK6WU787BMpvuugYwFeDl5qw5/view?usp=sharing",
      alt_name: "ssc",
      color_code: "#FFFFFF",
    },
    {
      title: "Soccer - Gold Medal",
      subtitle: "World Student Games - 2018",
      logo_path: "wsg.png",
      certificate_link:
        "https://drive.google.com/file/d/1XE-BP176-5o4qiK7vtg8r_Qbryvryc09/view?usp=sharing",
      alt_name: "wsc",
      color_code: "#FFFFFF",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description: "",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Programming Mentor",
          company: "Java, Processing",
          // company_url: "#/experience",
          logo_path: "tutor.png",
          duration: "February 2023 - June 2023",
          location: "Winnipeg, Manitoba, Canada",
          description:
            "I've been mentoring a student in private programming sessions, focusing on rebuilding her confidence. Here's my approach:\n- Prioritize an enjoyable and engaging learning experience.\n- Tailor lessons to the student's unique needs and learning style.\n- Emphasize a strong foundation in programming concepts.\n- Equipping the student with practical skills to achieve her goals.",
          color: "#9b1578",
        },
        {
          title: "Web Designer and Developer",
          company: "Ygrial Apparel",
          company_url: "",
          logo_path: "ygrial.jpg",
          duration: "May 2017 - Dec 2018",
          location: "Dhaka, Bangladesh. Worked Remotely from Winnipeg, Canada",
          description:
            "Designed an e-commerce website with integrated online payment.",
          color: "#0879bf",
        },
        {
          title: "Private Tutor",
          company: "Tutor for Students of Grade 8-10",
          // company_url: "#/experience",
          logo_path: "tutor.png",
          duration: "2012 - 2014",
          location: "Dhaka, Bangladesh",
          description:
            "-I have been a private tutor to students who need extra care with their studies to help them understand the study materials more clearly and do well in their examinations. \n-I have taught courses like Maths, Physics and Chemistry for grades 8 - 10.",
          color: "#9b1578",
        },
      ],
    },
    // {
    //   title: "Internships",
    //   experiences: [
    //     {
    //       title: "",
    //       company: "",
    //       company_url:
    //         "",
    //       logo_path: "",
    //       duration: "ov 2018 - Dec 2018",
    //       location: "",
    //       description:
    //         "",
    //       color: "#0071C5",
    //     },
    //   ],
    // },
    {
      title: "Volunteer",
      experiences: [
        {
          title: "Member of .dev Programming Club",
          company: "University of Manitoba Students Union (UMSU)",
          company_url: "https://www.facebook.com/pg/umdevclub/",
          logo_path: "devclub.png",
          duration: "Jan 2018 - Present",
          location: "Winnipeg, Manitoba",
          description:
            "I have been an active member of this club since 2018 and have been keeping up to date with:\n- Engaged with the latest game development technologies and tools,\n- Programming Discussions.",
          color: "#D83B01",
        },
        {
          title: "Developer Program Member",
          company: "GitHub",
          company_url: "https://github.com/vmsaif",
          logo_path: "github_logo.png",
          duration: "July 2019 - PRESENT",
          location: "Work From Home",
          description:
            "I am actively contributing to open-source projects. These contributions are issuing program bugs, and feature requests for the projects.",
          color: "#181717",
        },
        {
          title: "Student Council",
          company: "International College of Manitoba (ICM)",
          company_url:
            "https://www.icmanitoba.ca/microsites/predeparture/icm-student-services",
          logo_path: "icm_logo.png",
          duration: "May 2016 - August 2016",
          location: "Winnipeg, Manitoba",
          description:
            "I have helped newcomer international students in Winnipeg by providing information about courses, study skills and life in Winnipeg.",
          color: "#4285F4",
        },
        {
          title: "Ex-Member",
          company:
            "House of Volunteers - Ahsanullah University of Science and Technology (HOV-AUST)",
          company_url: "https://houseofvolunteers.org/",
          logo_path: "hov.png",
          duration: "2014 - 2015",
          location: "Dhaka, Bangladesh",
          description:
            "I have actively volunteered in programs like: \n - Opens Source Computer Education Program (OSCEP), \n- Earthquake Awareness Program (EAP), \n- Books for Bangladesh and Bangladesh Stockholm Junior Water Prize (SJWPBD). \nIn my short duration as a member of this volunteer organization. After the end of 2015, I left Bangladesh, thus the organization and came to Canada for my undergraduate study in Computer Science.",
          color: "#D83B01",
        },
        {
          title: "Class Captain",
          company: "Junior Laboratory High School",
          company_url: "https://www.facebook.com/junior.laboratory/",
          logo_path: "captain.png",
          duration: "2002 - 2012",
          location: "Dhaka, Bangladesh",
          description:
            "Throughout grades 1-10, I served as the class captain. My duties encompassed:\n- Assisting teachers and students with their needs,\n- Acting as the liaison between students and the school authority.\n\nFor grades 1-4, I was appointed by our Class Coordinator, and from grades 5-10, I was elected by my classmates. Our class coordinator, Mr. Rahat, regarded me as the best class captain he had seen for the standard of grade 6.",
          color: "#D83B01",
        },
      ],
    },
    {
      title: "Hobbies",
      experiences: [
        {
          title: "Video Gamer",
          company: "Personal Computer, PlayStation",
          // company_url: "",
          logo_path: "game.png",
          duration: "2001 - Current",
          location: "Winnipeg, Manitoba",
          description:
            "I have been a competitive gamer since my childhood. I have won:\n- Champion 2017 - The University of Manitoba Bangladesh Student Association (UMBSA), FIFA 16 - Solo Tournament.\n- Champion 2018 - Canadian Bangladeshi Association (CBA), FIFA 17 - Dual Tournament with my friend Ashfiqur Akash.\n- Intel PC Guru PC Build 2007 (The fastest 8 people to build a PC have won iPod Shuffle. I placed 3rd.)\nI also participated in the World Cyber Games, Bangladesh in 2007 and 2008 in the games FIFA 06 and FIFA 07 respectively.",
          color: "#4285F4",
        },
        {
          title: "Repairing Computers/DIY electronic Projects",
          company: "Self-taught",
          company_url: "",
          logo_path: "repair.png", //Downloaded from https://www.clipartmax.com/middle/m2i8m2i8H7d3m2A0_proactive-it-support-computer-repair-png/
          duration: "2005 - Current",
          location: "Dhaka, Bangladesh",
          description:
            "I've had a passion for troubleshooting and repairing computers since early childhood. My expertise encompasses:\n- Moderate proficiency in both hardware and software diagnostics.\n- Pinpointing specific hardware components causing issues.\n- Regularly updating my knowledge with the latest tech advancements.\n- Assisting friends and family in optimizing their computer performance.\n- Successfully reviving older systems to function efficiently.\n- Familiarity with various operating systems and their common issues.",
          color: "#ee3c26",
        },
        {
          title: "Soccer, Outdoor - Football Club Vikings",
          company: "Player & Managing Partner",
          company_url: "https://www.facebook.com/vikingsisthename",
          logo_path: "vikings_logo.png",
          duration: "2012 - 2014",
          location: "Dhaka, Bangladesh",
          description:
            'In Dhaka, Bangladesh;\n- I have been a defensive midfielder and actively participated in team management with other partners.\n- Here I have learned what it takes to be a "team player" and how to deal with unexpected management challenges in a team of 26 players.\n\nIn Winnipeg, Canada, in a separate team;\n- We have won the gold medal in Soccer - World Student Games 2018\n- We were the champion of the Canadian Bangladeshi Association (CBA) Soccer Tournament - 2017',
          color: "#181717",
        },
        {
          title: "Travelling",
          company:
            "I love to travel with my friends, family, or sometimes by myself. I have traveled to many places.",
          // company_url: "",
          logo_path: "travel.png",
          duration: "2014 - 2015",
          location: "Bangladesh, Canada",
          description:
            "Apart from my present address in Canada, I have traveled:\n-- Banff,\n-- Calgary,\n-- Kamloops,\n-- Vancouver,\n-- Toronto,\n-- Winnipeg.\n\nIn Bangladesh, I have travelled:\n-- Cox's Bazar,\n-- Kuakata,\n-- Bandorban,\n-- Sylhet,\n-- Khagrachori,\n-- Chittagong,\n-- Rangamati,\n-- Tangail.",
          color: "#D83B01",
        },
        {
          title: "Campfire Guitarist",
          company:
            "I play guitar for myself and occasionally with my friends at get-togethers.",
          // company_url: "",
          logo_path: "guitar.png",
          duration: "2017 - Current",
          // location: "",
          description: "",
          color: "#D83B01",
        },
        {
          title: "Cooking",
          company: "I started cooking at an early age.",
          company_url: "",
          logo_path: "cooking.png",
          duration: "2011 - Current",
          location: "Winnipeg, Manitoba",
          description:
            "Growing up, my mother passed down our traditional cooking techniques to me.\nAfter relocating to Canada for my undergraduate studies, this culinary foundation proved invaluable.\nOver the past decade, I've honed my skills in the kitchen. Not only do I prepare meals for myself, but I also enjoy cooking for friends during our get-togethers at my place.",
          color: "#D83B01",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects make use of a vast variety of latest technology tools.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "A collection of research papers I've authored, encompassing various topics in technology and its implications.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "1",
      name: "Case Study: Clearview AI and Copywrite",
      createdAt: "2021-06-13T16:26:54Z",
      description:
        "The term “Artificial Intelligence” (AI) was coined for the first time in 1955. Since then, AI has evolved considerably. Even after 56 years, there are no clear policies and laws regarding the use of AI on intellectual properties (IP) and often issues are handled with the term “policy exceptions”. This paper delves into the need for distinct policies for AI and data mining, especially in the context of Intellectual Properties.",
      url:
        "https://drive.google.com/file/d/1fhzj79t0O2VPpqwj4KYu9h9lD67EDPun/view?usp=sharing",
    },
    {
      id: "2",
      name:
        "Case Study: Waterfront Toronto and Sidewalk Lab’s Quayside Project",
      createdAt: "2021-05-31T16:26:54Z",
      description:
        "Sidewalk Lab’s Quayside Project failure indicates the paramount importance of privacy transparency and trust in the stability of tech projects. This paper analyzes the reasons behind the project's failure and emphasizes the need for a redesigned privacy and data mining model.",
      url:
        "https://drive.google.com/file/d/1MSxdJLkHMefLxjUs0YSpbaMvB-PBZzsu/view?usp=sharing",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "SaifMahmud.png",
    description:
      "I am available on almost every social media. You can message me and I will reply within 24 hours. I can help you with AI, Database, Computer Networks, Human Computer Interaction, Android development and Computer Graphics.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create an impact on each of the readers individually to change the core of their character.",
    // link: "#/contact",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Winnipeg, Manitoba, Canada",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/e9npQce1VkhQa4Rk6",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+1 204-955-5064",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publications,
  publicationsHeader,
  contactPageData,
};
